import React from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import Event from "../components/Event/Event";
import "./calendar.scss";

export default function CalendarPage ({data}) {

  const events = data.allMarkdownRemark.nodes.map((eventNode)=>{
    return(
      <Event event={eventNode}></Event>
    )
  });

    return (
        <Layout>
          <main className="calendarPage">
            <div className="calendarPage__meta">
              <h1 className="calendarPage__title">What's On</h1>
              {/* <NextEvent></NextEvent> */}
            </div>
            {events}
          </main>
        </Layout>
    );
}

export const pageQuery = graphql`
    query calendarQuery {
      allMarkdownRemark(filter: {frontmatter: {type: {eq: "Calendar"}}},
      sort: {fields: frontmatter___date}) {
      nodes {
          frontmatter {
              path
              remoteurl
              title
              date(formatString: "dddd LL")
              address
              round
              featured {
                  publicURL
              }
          }
      }
    }
  }
  `