import React from "react"
import IconButton from "../IconButton/IconButton"
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import BottomBorder from "../BottomBorder/BottomBorder"
import "./event.scss"

export default function Event ({event}) {

    const frontmatter = event.frontmatter
    return(
        <section title="Upcoming Event" className="event">
            <div className="event__inner">
                <div className="event__body">
                <em className="event__heading">Upcoming Event</em>
                <h2 className="event__title">
                    {frontmatter.title}
                </h2>
                <p>
                    {frontmatter.address}<br/>
                    {frontmatter.round}<br/>
                    {frontmatter.date}
                </p>

                <IconButton 
                    imgIconFa={faCalendar}
                    linkTarget={frontmatter.remoteurl}
                    linkText="Learn More"
                    ></IconButton>
                </div>
                <img width="300"
                    className="event__featuredImg" 
                    src={frontmatter.featured.publicURL} 
                    alt="2020 PZM WARSAW FIM SPEEDWAY GRAND PRIX OF POLAND Track"/>


            </div>
            <BottomBorder/>
        </section>
    )
}
